import { generateQuery } from "helpers/utis";

export const AuthApi = {
  loginApi: {
    api: "/admin/login",
    baseURL: "user",
    method: "post",
  },
  forgotPasswordApi: {
    api: "/admin/forgotPassword",
    baseURL: "user",
    method: "post",
  },
  resetPasswordApi: {
    api: "/admin/resetPassword",
    baseURL: "user",
    method: "post",
  },
  logoutApi: {
    api: "/user/logout",
    baseURL: "user",
    method: "get",
  },
  changePasswordApi: {
    api: "/admin/changePassword",
    baseURL: "user",
    method: "post",
  },
};

export const dashboardApi = {
  getDashboardData: {
    api: "/dashboard/getDashboardCounts",
    baseURL: "booking",
    method: "get",
  },
  getUserStatisticsData: {
    url: "/user/userDashboard",
    baseURL: "user",
    method: "get",
    query: { startDate: "", toDate: "" },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getPackagesData: {
    url: "/dashboard/packagesGraph",
    baseURL: "booking",
    method: "get",
    query: { startDate: "", toDate: "" },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getBookingsData: {
    url: "/dashboard/packagesAndTestBookingGraph",
    baseURL: "booking",
    method: "get",
    query: { startDate: "", toDate: "" },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
};

export const BlogApi = {
  blogListApi: {
    url: "/blogs/blogLists",
    baseURL: "booking",
    method: "get",
    query: { limit: 10, page: 1, search: null, status: null },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  createBlogApi: {
    api: "/blogs/createBlogs",
    baseURL: "booking",
    method: "post",
  },
  createCategoryApi: {
    api: "/blogs/createBlogCategory",
    baseURL: "booking",
    method: "post",
  },
  updateCategoryApi: {
    url: "/blogs/updateBlogCategory",
    baseURL: "booking",
    method: "put",
    id: null,
    get api() {
      return this.url + "?id=" + this.id;
    },
  },
  blogDetailApi: {
    url: "/blogs/getBlogDetail/",
    baseURL: "booking",
    method: "get",
    slug: null,
    get api() {
      return this.url + this.slug;
    },
  },
  deleteBlogApi: {
    url: "/blogs/deleteBlogs?id=",
    baseURL: "booking",
    method: "delete",
    id: null,
    get api() {
      return this.url + this.id;
    },
  },
  updateBlogApi: {
    url: "/blogs/updateBlogs?id=",
    baseURL: "booking",
    method: "put",
    id: null,
    get api() {
      return this.url + this.id;
    },
  },
  uploadBlogImagesApi: {
    api: "/upload/uploadBlogImages",
    baseURL: "booking",
    method: "post",
  },
  deleteBlogImagesApi: {
    api: "/upload/deleteBlogImages",
    baseURL: "booking",
    method: "post",
  },
  uploadPdfApi: {
    api: "/upload/uploadPdf",
    baseURL: "booking",
    method: "post",
  },
  deletePdfApi: {
    api: "/upload/deletePdf",
    baseURL: "booking",
    method: "post",
  },
  categoryListApi: {
    api: "/blogs/blogCategoriesList",
    baseURL: "booking",
    method: "get",
  },
  packagesListApi: {
    api: "/package/packageForBlog",
    baseURL: "booking",
    method: "get",
  },
  testListApi: {
    api: "/package/testsForBlog",
    baseURL: "booking",
    method: "get",
  },
  deleteCategoryApi: {
    url: "/blogs/deleteBlogCategory?id=",
    baseURL: "booking",
    method: "delete",
    id: null,
    get api() {
      return this.url + this.id;
    },
  },
  usersListApi: {
    url: "/admin/userList",
    baseURL: "user",
    method: "get",
    query: { limit: 10, page: 1, search: null },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  downloadCSVApi: {
    api: "/admin/exportUserData",
    baseURL: "user",
    method: "get",
  },
  usersEnquiresListApi: {
    url: "/enquiry/userEnquiresList",
    baseURL: "booking",
    method: "get",
    query: { limit: 10, page: 1, search: null },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  downloadCSVEnquiresApi: {
    api: "/enquiry/exportUserEnquiresData",
    baseURL: "booking",
    method: "get",
  },
  newsLetterListApi: {
    url: "/newsletter/listNewsletters",
    baseURL: "booking",
    method: "get",
    query: { limit: 10, page: 1, search: null },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  uploadThumbImagesApi: {
    api: "/upload/uploadThumbnailImage",
    baseURL: "booking",
    method: "post",
  },
  uploadNewsLetterApi: {
    api: "/upload/uploadNewsletters",
    baseURL: "booking",
    method: "post",
  },
  createNewsLetterApi: {
    api: "/newsletter/createNewsletter",
    baseURL: "booking",
    method: "post",
  },
  updateNewsLetterApi: {
    url: "/newsletter/updateNewsletter?id=",
    baseURL: "booking",
    method: "put",
    id: null,
    get api() {
      return this.url + this.id;
    },
  },
  inactiveNewsLetterApi: {
    url: "/newsletter/inactivateNewsletter?id=",
    baseURL: "booking",
    method: "put",
    id: null,
    get api() {
      return this.url + this.id;
    },
  },
  activeNewsLetterApi: {
    url: "/newsletter/activateNewsletter?id=",
    baseURL: "booking",
    method: "put",
    id: null,
    get api() {
      return this.url + this.id;
    },
  },
};

export const transactionApi = {
  transactionListApi: {
    url: "/service/bookingHistory",
    baseURL: "booking",
    method: "get",
    query: {
      limit: 10,
      page: 1,
      search: "",
      type: "",
      cityId: "",
      stateId: "",
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  transactionGraphViewApi: {
    url: "/service/bookingHistoryByGraphView",
    baseURL: "booking",
    method: "get",
    query: {
      type: "Package",
      search: "",
      stateId: "",
      cityId: "",
      fromDate: "",
      toDate: "",
      filter: "",
    },
    get api() {
      return (
        this.url +
        "?type=" +
        this.query.type +
        "&search=" +
        this.query.search +
        "&stateId=" +
        this.query.stateId +
        "&cityId=" +
        this.query.cityId +
        "&fromDate=" +
        this.query.fromDate +
        "&toDate=" +
        this.query.toDate +
        "&filter=" +
        this.query.filter
      );
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getCancelRequestData: {
    url: "/admin/getBookingCancelDetails?bookingId=",
    baseURL: "payment",
    method: "get",
    id: null,
    get api() {
      return this.url + this.id;
    },
  },
  acceptCancelRequest: {
    api: "/admin/approveCancelRequest",
    baseURL: "payment",
    method: "post",
  },
  rejectCancelRequest: {
    api: "/admin/rejectCancelRequest",
    baseURL: "payment",
    method: "post",
  },
};

export const bookingReportsApi = {
  bookingReportsListApi: {
    url: "/service/getbooking/completed",
    baseURL: "booking",
    method: "get",
    query: {
      limit: 10,
      page: 1,
      search: null,
      cityId: null,
      stateId: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  }
};

export const reportsApi = {
  reportsListApi: {
    url: "/service/sendReportHistory",
    baseURL: "booking",
    method: "get",
    query: {
      limit: 10,
      page: 1,
      search: null,
      cityId: null,
      stateId: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  addReportsApi: {
    api: "/service/addReports",
    baseURL: "booking",
    method: "post",
  },
  uploadPatientReportApi: {
    api: "/upload/uploadPatientReports",
    baseURL: "booking",
    method: "post",
  },
  deletePatientReportApi: {
    api: "/upload/deletePatientReports",
    baseURL: "booking",
    method: "post",
  },
};

export const diagnosticApi = {
  getCategoryApi: {
    url: "/package/getAllPackageCategories",
    baseURL: "booking",
    method: "get",
    query: { limit: 10, page: 1, search: null, status: null },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getSingleCategory: {
    url: "/package/categories/",
    baseURL: "booking",
    method: "get",
    id: null,
    get api() {
      return this.url + this.id;
    },
  },
  getCategoryDropDown: {
    url: "/package/getAllPackageCategories",
    baseURL: "booking",
    method: "get",
    query: { limit: 1000, page: 1, search: null, status: null },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  categoryCreateApi: {
    api: "/package/newCategory",
    baseURL: "booking",
    method: "post",
  },
  categoryDeleteApi: {
    url: "/package/deletePackageCatgories/",
    baseURL: "booking",
    method: "delete",
    id: null,
    get api() {
      return this.url + this.id;
    },
  },
  updatePackageCategoryApi: {
    url: "/package/UpdatepacakageCatgories/",
    baseURL: "booking",
    method: "put",
    id: null,
    get api() {
      return this.url + this.id;
    },
  },
  getSubCategoryApi: {
    url: "/package/getAllPackageSubCategories",
    baseURL: "booking",
    method: "get",
    query: { limit: 10, page: 1, search: null, status: null },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  createSubCategoryApi: {
    api: "/package/newSubCategory",
    baseURL: "booking",
    method: "post",
  },
  updateSubCategoryApi: {
    url: "/package/updatepackageSubCategories/",
    baseURL: "booking",
    method: "put",
    id: null,
    get api() {
      return this.url + this.id;
    },
  },
  subCategoryDeleteApi: {
    url: "/package/DeletePackageSubCategories/",
    baseURL: "booking",
    method: "delete",
    id: null,
    get api() {
      return this.url + this.id;
    },
  },
  getLocationApi: {
    url: "/location/centerLocationList",
    baseURL: "booking",
    method: "get",
    query: { limit: 10, page: 1, search: null, status: null },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getConditionDropDown: {
    url: "/package/getAllCondition",
    baseURL: "booking",
    method: "get",
    query: { limit: 1000, page: 1, search: null, status: null },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getConditionApi: {
    url: "/package/getAllCondition",
    baseURL: "booking",
    method: "get",
    query: { limit: 10, page: 1, search: null, status: null },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  createConditionApi: {
    api: "/package/newCondition",
    baseURL: "booking",
    method: "post",
  },
  updateConditionApi: {
    url: "/package/updateCondition/",
    baseURL: "booking",
    method: "put",
    id: null,
    get api() {
      return this.url + this.id;
    },
  },
  deleteConditionApi: {
    url: "/package/deleteCondition/",
    baseURL: "booking",
    method: "delete",
    id: null,
    get api() {
      return this.url + this.id;
    },
  },
  getTestApi: {
    url: "/package/getAllPacakageSubChildCatgories",
    baseURL: "booking",
    method: "get",
    query: { limit: 10, page: 1, search: null, status: null },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getTestDropDown: {
    url: "/package/getAllPacakageSubChildCatgories",
    baseURL: "booking",
    method: "get",
    query: { limit: 1000, page: 1, search: null, status: null },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getSingleTest: {
    url: "/package/subChildCategories/",
    baseURL: "booking",
    method: "get",
    id: null,
    get api() {
      return this.url + this.id;
    },
  },
  createTestApi: {
    api: "/package/newTest",
    baseURL: "booking",
    method: "post",
  },
  updateTestApi: {
    url: "/package/updatePacakageSubChildCategories/",
    baseURL: "booking",
    method: "put",
    id: null,
    get api() {
      return this.url + this.id;
    },
  },
  deleteTestApi: {
    url: "/package/DeletePacakgeSubChildCatgories/",
    baseURL: "booking",
    method: "delete",
    id: null,
    get api() {
      return this.url + this.id;
    },
  },
  getPackageApi: {
    url: "/package/getAllPackage",
    baseURL: "booking",
    method: "get",
    query: { limit: 10, page: 1, search: null, status: null },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getSinglePackage: {
    url: "/package/getpackage/",
    baseURL: "booking",
    method: "get",
    id: null,
    get api() {
      return this.url + this.id;
    },
  },
  createPackageApi: {
    api: "/package/newPackage",
    baseURL: "booking",
    method: "post",
  },
  packageDeleteApi: {
    url: "/package/DeletePacakges/",
    baseURL: "booking",
    method: "delete",
    id: null,
    get api() {
      return this.url + this.id;
    },
  },
  updatePackageApi: {
    url: "/package/updatePacakages/",
    baseURL: "booking",
    method: "put",
    id: null,
    get api() {
      return this.url + this.id;
    },
  },
};
export const couponApi = {
  getCoupon: {
    url: "/offer/getAllCoupon",
    baseURL: "booking",
    method: "get",
    query: { limit: 10, page: 1, search: null, status: null },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getSingleCoupon: {
    url: "/offer/getById/",
    baseURL: "booking",
    method: "get",
    id: null,
    get api() {
      return this.url + this.id;
    },
  },
  createCoupon: {
    api: "/offer/newCoupon",
    baseURL: "booking",
    method: "post",
  },
  updateCoupon: {
    url: "/offer/updateCoupon/",
    baseURL: "booking",
    method: "put",
    id: null,
    get api() {
      return this.url + this.id;
    },
  },
  deleteCoupon: {
    url: "/offer/deleteCoupon/",
    baseURL: "booking",
    method: "delete",
    id: null,
    get api() {
      return this.url + this.id;
    },
  },
};
export const commonApi = {
  stateListApi: {
    api: "/location/serviceStates",
    baseURL: "booking",
    method: "get",
  },
  cityListApi: {
    url: "/location/serviceCitiesBasedStates?state=",
    baseURL: "booking",
    method: "get",
    stateId: null,
    get api() {
      return this.url + this.stateId;
    },
  },
  getCategoryData: {
    url: "/package/getAllPackageCategories",
    baseURL: "booking",
    method: "get",
    id: null,
    get api() {
      if (this.id) {
        return this.url + "/" + this.id;
      } else {
        return this.url;
      }
    },
  },
  getSubCategoryData: {
    url: "/package/getAllPackageSubCategories",
    baseURL: "booking",
    method: "get",
    query: { limit: 1000, page: 1, search: null, status: null },
    id: null,
    get api() {
      if (this.id) {
        return this.url + "/" + this.id;
      } else {
        return this.url + generateQuery(this.query);
      }
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getConditionData: {
    url: "/package/getAllCondition",
    baseURL: "booking",
    method: "get",
    id: null,
    get api() {
      if (this.id) {
        return this.url + "/" + this.id;
      } else {
        return this.url;
      }
    },
  },
  getCenterLocationData: {
    api: "/location/centerLocationList",
    baseURL: "booking",
    method: "get",
  },
};


export const StateApi = {
  stateApi: {
    api: "/location/states",
    baseURL: "booking",
    method: "get",
  },
  createStateApi: {
    api: "/location/createStates",
    baseURL: "booking",
    method: "post",
  },
  updateStateApi: {
    url: "/location/updateStates?id=",
    baseURL: "booking",
    method: "put",
    id: null,
    get api() {
      return this.url + this.id;
    },
  },
  deleteStateApi: {
    url: "/location/deleteStates?id=",
    baseURL: "booking",
    method: "delete",
    id: null,
    get api() {
      return this.url + this.id;
    },
  },
};


export const CitiesApi = {
  cityApi: {
    api: "/location/citiesList",
    baseURL: "booking",
    method: "get",
  },
  createCityApi: {
    api: "/location/createCities",
    baseURL: "booking",
    method: "post",
  },
  updateCityApi: {
    url: "/location/updateCities?id=",
    baseURL: "booking",
    method: "put",
    id: null,
    get api() {
      return this.url + this.id;
    },
  },
  deleteCityApi: {
    url: "/location/deleteCities?id=",
    baseURL: "booking",
    method: "delete",
    id: null,
    get api() {
      return this.url + this.id;
    },
  },
};


export const CenterLocationsApi = {
  centerLocationsApi: {
    api: "/location/centerLocationList",
    baseURL: "booking",
    method: "get",
  },
  centerLocationsByIdApi: {
    url: "/location/centerLocationsById?id=",
    baseURL: "booking",
    method: "get",
    id: null,
    get api() {
      return this.url + this.id;
    },
  },
  createCenterLocationsApi: {
    api: "/location/createCenterLocations",
    baseURL: "booking",
    method: "post",
  },
  updateCenterLocationsApi: {
    url: "/location/updateCenterLocations?id=",
    baseURL: "booking",
    method: "put",
    id: null,
    get api() {
      return this.url + this.id;
    },
  },
  deleteCenterLocationsApi: {
    url: "/location/deleteCenterLocations?id=",
    baseURL: "booking",
    method: "delete",
    id: null,
    get api() {
      return this.url + this.id;
    },
  },
};


export const BookingsApi = {
  bookingsApi: {
    url: "/booking/getBooking",
    baseURL: "booking",
    method: "get",
    query: { limit: 10, page: 1, search: null },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  downloadCSVApi: {
    url: "/booking/exportBookingData",
    baseURL: "booking",
    method: "get",
    query: { limit: 10, page: 1, search: null },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
};